window._ = require('lodash');
window.Popper = require('popper.js').default;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require('jquery');

  require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.response.use(
  function(response) {
    let gaEvent = {
      event: 'axiosRequest',
      attributes: {
        type: response.config.method || '',
        url: response.config.url || '',
        queryParameters: response.config.data || '{}',
        statusCode: response.status || '',
        statusText: response.statusText || '',
        status: 'post_failed',
        headers: response.headers,
        data: response.data.data || '{}',
      },
    };
    if (response && response.config.method === 'post' && response.data && response.data.success && response.data.data.form_type) {
      gaEvent.attributes.status = `post_success_${response.data.data.form_type}`;
      if(window.hasOwnProperty('dataLayer') && dataLayer){
          dataLayer.push(gaEvent);
      }
    }

    return response;
  },
  function(error) {
    return Promise.reject(error);
  },
);

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
