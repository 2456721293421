/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./vendor/fontawesome-all.min');
require('./vendor/masonry');
require('../../../node_modules/dragscroll/dragscroll.js');
require('./bootstrap');
require('./polyfills');

import Vue from 'vue';

import SvgVue from 'svg-vue';
Vue.use(SvgVue);

import VeeValidateNl from 'vee-validate/dist/locale/nl';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    locale: 'nl',
    dictionary: {
        nl: {
            messages: VeeValidateNl.messages,
        },
    },
});

import { VueMasonryPlugin } from 'vue-masonry';
Vue.use(VueMasonryPlugin);

import VueMq from 'vue-mq';
Vue.use(VueMq, {
    breakpoints: {
        mobile: 768,
        tablet: 992,
        laptop: 1200,
        lg: Infinity,
    },
});

import VueSelect from 'vue-select';
Vue.component('v-select', VueSelect);

/**
 * Page components
 * - Contact form
 * - Estate filters page
 */
Vue.component('contact-form', () => import('./components/ContactForm.vue'));
Vue.component('estates-filter', () => import('./components/estates/filter.vue'));
Vue.component('estate-details', () => import('./components/estates/detail.vue'));
Vue.component('keep-me-posted', () => import('./components/estates/keep-me-posted.vue'));

/**
 * Single componenets
 * - Estate card
 * - Estate of the month
 * - News card
 * - Testimonial card
 */
Vue.component('estate-card', () => import('./components/cards/Estate.vue'));
Vue.component('project-card', () => import('./components/cards/Project.vue'));
Vue.component('news-card', () => import('./components/cards/News.vue'));
Vue.component('testimonial-card', () => import('./components/cards/Testimonial.vue'));
Vue.component('spotlight', () => import('./components/general/Spotlight.vue'));
Vue.component('loader', () => import('./components/general/loader.vue'));

// RESTYLING VASTGOEDC NEW COMPONENTS
Vue.component('team-members', () => import('./components/team-members.vue'));
Vue.component('cta-vacancies', () => import('./components/cta-vacancies.vue'));
Vue.component('our-services', () => import('./components/our-services.vue'));
Vue.component('team-map', () => import('./components/team-map.vue'));
Vue.component('services-dropdown', () => import('./components/services-dropdown.vue'));
Vue.component('testimonial-services', () => import('./components/testimonial-services.vue'));
Vue.component('vacancies', () => import('./components/vacancies.vue'));
Vue.component('vacancy-form', () => import('./components/vacancy-form.vue'));
Vue.component('conditional-video', () => import('./components/parts/conditional-video.vue'));
Vue.component('gdpr', () => import('./gdpr.vue'));

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

$(document).ready(function () {
    // Initialize
    $('.tooltipEl').tooltip();
});

/**
 * Handle server errrors
 * - If validation error show an array of validation errors
 * - If exception show the exception message in the array
 * - If anything else show the message 'Something went wrong'
 */
window.handleError = function (error) {

    if(!error.response){
        console.error(error)
        return [['Something went wrong.']]
    }
    if(!error.response.hasOwnProperty('data')){
        console.error(error)
        return [['Something went wrong.']]
    }
    if (error.response.data.error) return error.response.data.error;
    else if (error.response.data.errors) return error.response.data.errors;
    else if (error.response.data.exception) return [[error.response.data.message]];
    else return [['Something went wrong.']];
};

/**
 * Handle server success messages
 */
window.handleSuccess = function (success) {
    if (success.data.success) return success.data.data.message;
    else return [['Success.']];
};

/**
 * Get Current URL
 */
Vue.prototype.getCurrentUrl = function () {
    return window.location.href;
};

/**
 * Slugify a given text string
 * The text will be set to lowercase and all spaces
 * will be replaced with '-' signs
 */
Vue.prototype.slugMe = function (text) {
    if (!text) return '';

    let slug = text.replace(/^\s+|\s+$/g, '').toLowerCase();
    let from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    let to = 'aaaaeeeeiiiioooouuuunc------';

    for (let i = 0, l = from.length; i < l; i++) {
        slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    slug = slug
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return slug;
};

/**
 * Get route params
 * search all route params and
 * push them on an array [ name : value ]
 */
window.getParam = function (name) {
    let result = '';
    let tmp = [];
    let items = location.search.substr(1).split('&');

    for (let i = 0; i < items.length; i++) {
        tmp = items[i].split('=');
        if (tmp[0] === name) result = decodeURIComponent(tmp[1]);
    }

    return result;
};

/**
 * Format price
 * Format a given number to the correct price
 */
Vue.prototype.formatPrice = function (price, currency, purposeStatusId) {
    if (purposeStatusId && (purposeStatusId === 3 || purposeStatusId === 4)) return '';

    if (parseInt(price) < 2) return 'Prijs op aanvraag';

    if (!price) return '';

    price = `${price}`.replace(/,/g, '.');
    return currency + ' ' + Number(price).toLocaleString('nl-BE', { minimumFractionDigits: 2 });
};

/**
 * Format a value by its Whise type
 * Format a given value by it's whise type
 */
Vue.prototype.formatByType = function (value, type) {
    if (type === 'int') return parseInt(value);
    if (type === 'surface') return value + ' m<sup>2</sup>';
    if (type === 'yes/no') return value === '1' ? 'ja' : 'nee';
    if (type === 'length') return value + ' m';
    if (type === 'elecCertifConformity') return value;
    if (type === 'liter') return value + ' l';
    if (type === 'garden') return value ? 'ja' : 'nee';
    if (type === 'Price') return this.formatPrice(value, '€');
    if (type === 'price') return this.formatPrice(value, '€');
    if (type === 'groundArea') return value + ' m<sup>2</sup>';
    if (type === 'area') return value + ' m<sup>2</sup>';
    if (type === 'gardenArea') return value + ' m<sup>2</sup>';
    if (type === 'kitchen') return 'volledig geïnstalleerd';
    if (type === 'availabilityDateTime') {
        return convertEpochandTimezone(new Date(value.date))
    };
    if (type === 'category') {
        return Vue.prototype.getCategoryName(value.id);
    }
    if (type === 'state') {
        return Vue.prototype.getStateName(value.id);
    }
    if (type === 'availability') {
        return Vue.prototype.getAvailabilityName(value.id);
    }
    return value;
};

Vue.prototype.formatByLabel = function (label) {
    if (label === 'bouwvergunning') return 'Bouwvergunning';
    if (label === 'dagvaarding') return 'Dagvaarding';
    if (label === 'voorkooprecht') return 'Voorkooprecht';
    if (label === 'verkavelingvergunning') return 'Verkavelingsvergunning';
    if (label === 'terrein - bestemming') return 'Recente bestemming';
    if (label === 'electriciteit') return 'Elektriciteit';
    if (label === 'riolering') return 'Riolering';
    if (label === 'telefoonbekabeling') return 'Telefoonlijn';
    if (label === 'water') return 'Waterleiding';
    if (label === 'elektriciteitskeuring beschikbaar') return 'Elektriciteitskeuring beschikbaar';
    if (label === 'bodemattest') return 'Bodemattest';
    if (label === 'type verwarming') return 'Verwarming';
    if (label === 'dubbele beglazing') return 'Dubbele beglazing';
    if (label === 'keuken - type') return 'Keukentype';
    if (label === 'capaciteit olietank') return 'Inhoud stookolietank';
    if (label === 'EPC unieke code') return 'EPC Nº';
    if (label === 'EPC (Kwh/m²/j)') return 'EPC Waarde (kWh/m²)';
    if (label === 'Price') return 'Prijs';
    if (label === 'kadastraal inkomen (€) - bedrag') return 'KI';
    if (label === 'Availability') return 'Vrij op';
    if (label === 'AvailabilityDateTime') return 'Vrij op';
    if (label === 'Garden') return 'Tuin';
    if (label === 'terras 1 - oriëntatie') return 'Oriëntatie terras';
    if (label === 'Oriëntatie') return 'Oriëntatie achtergevel';
    if (label === 'toiletten - aantal') return 'Aantal toiletten';
    if (label === 'State') return 'Staat van het pand';
    if (label === 'gevelbreedte - breedte') return 'Gevelbreedte';
    if (label === 'Grond diepte') return 'Perceeldiepte';
    if (label === 'verdiepingen - aantal') return 'Aantal verdiepingen';
    if (label === 'type dak - type') return 'Type dak';
    if (label === 'winkels in de buurt ') return 'Winkels in de buurt';
    if (label === 'winkels') return 'Winkelafstand';
    if (label === 'openbaar vervoer in de buurt ') return 'Openbaar vervoer in de buurt';
    if (label === 'openbaar vervoer') return 'Openbaar vervoer';
    if (label === 'scholen in de buurt ') return 'Scholen in de buurt';
    if (label === 'scholen') return 'Scholen';
    if (label === 'O-Peil') return 'Overstromingsgebied';
    if (label === 'bureau') return 'Bureau';
    if (label === 'kelder') return 'Kelder';
    if (label === 'zolder') return 'Zolder';
    if (label === 'veranda') return 'Veranda';
    if (label === 'wasplaats') return 'Wasplaats';
    if (label === 'renovatie - jaar') return 'Renovatiejaar';
    if (label === 'bijkeuken') return 'Bijkeuken';
    if (label === 'open haard') return 'Open haard';
    if (label === 'jacuzzi') return 'Jacuzzi';
    if (label === 'details bureau') return 'Bureau details';
    if (label === 'details kelder') return 'Kelder details';
    if (label === 'details zolder') return 'Zolder details';
    if (label === 'waarde constructie - waarde') return 'Waarde constructie - waarde';
    if (label === 'waarde terrein - waarde') return 'Waarde terrein - waarde';
    if (label === 'rooms') return 'Aantal slaapkamers';
    if (label === 'bathRooms') return 'Aantal badkamers';
    if (label === 'groundArea') return 'Grondoppervlakte';
    if (label === 'area') return 'Bewoonbare oppervlakte';
    if (label === 'gardenArea') return 'Oppervlakte tuin';
    if (label === 'category') return 'Kadastrale aard';
    if (label === 'availabilityDateTime') return 'Beschikbaar vanaf';
    if (label === 'availability') return 'Beschikbaarheid';
    if (label === 'state') return 'Staat';
    if (label === 'garden') return 'Tuin';
    if (label === 'price') return 'Prijs';

    return label;
};

Vue.prototype.getPurposeName = function (id) {
    const values = { 1: 'for sale', 2: 'for rent', 3: 'life annuity sale' };

    return values[id];
};

Vue.prototype.getPurposeStatusName = function (id) {
    const values = {
        1: 'for sale',
        2: 'for rent',
        3: 'sold',
        4: 'rented',
        5: 'under offer (sale)',
        6: 'under offer (rent)',
        8: 'out of sales',
        9: 'out of rent',
        10: 'suspended sales',
        11: 'suspended rent',
        12: 'option owner s.',
        13: 'option owner r.',
        14: 'sold STC (Subject to Contract)',
        15: 'for sale',
        16: 'under offer (sale)',
        17: 'sold',
        19: 'prospection',
        20: 'preparation',
        21: 'reserved',
        22: 'draft contract',
        23: 'prospection rent',
        24: 'estimate s.',
        25: 'estimate r.',
        26: 'estimate l.a.'
    };
    return values[id];
};

Vue.prototype.getCategoryName = function (id) {
    console.log(id)
    const values = {
        1: 'huis',
        2: 'appartement',
        3: 'grond',
        4: 'kantoor',
        5: 'commercieel',
        6: 'industrieel',
        7: 'garage/parkeerplaats'
    };
    return values[id];
};

Vue.prototype.getSubcategoryName = function (id) {

};

Vue.prototype.getAvailabilityName = function (id) {
    const values = {
        1: "bij het contract",
        2: "tbd met de eigenaar",
        3: "tbd met de huurder",
        4: "niet beschikbaar",
        5: "onmiddellijk",
        6: "bij oplevering",
        7: "nader overeen te komen",
    }

    return values[id]
};

Vue.prototype.getStateName = function (id) {
    const values = {
        1: "nieuw",
        2: "zeer goede staat",
        3: "gerenoveerd",
        4: "goede staat",
        5: "worden opgefrist",
        6: "worden gerenoveerd",
        7: "worden herbouwd",
        8: "af te breken",
        9: "onbekend",
        10: "Groen landgoed",
        11: "te bouwen",
        12: "groot werk",
        13: "nieuwbouw",
        14: "kleine renovatie",
        15: "gerenoveerd",
        16: "concept",
        17: "ontwikkeld",
        18: "klaar om te beginnen met bouwen",
        19: "muren gesloten",
        20: "bouw gerealiseerd",
        21: "opgeleverd",
        22: "gestuct",
        23: "te prepareren grond",
        24: "grond gereed voor bouw",
    }
    return values[id];
};

new Vue({
    el: '#app',
})

$(document).ready(function () {
    $('.collapse').on('show.bs.collapse', function () {
        $('.nav-mobile').addClass('open');
    });
    $('.collapse').on('hidden.bs.collapse', function () {
        $('.nav-mobile').removeClass('open');
    });
});

$(document).ready(function () {
    $('a.smooth').on('click', function (event) {
        if (this.hash !== '') {
            event.preventDefault();
            let hash = this.hash;
            $('html, body').animate({ scrollTop: $(hash).offset().top - 80 }, 800, function () { });
        }
    });
});

// ADD BOX SHADOW TO HEADER NAVIGATON WHEN SCROLL DOWN
$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
        $('#scroll-shadow').addClass('add-box-shadow');
    } else {
        $('#scroll-shadow').removeClass('add-box-shadow');
    }
});

//Eepoch date to datetime
function convertEpochandTimezone(date) {
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
}
